import authWsbFe from '@blaze-cms/plugin-auth-wsb-fe';
import ecommerceComponents from '@blaze-cms/plugin-ecommerce-components';
import examplePlugin from './blaze-plugins/example';
import ecommerceKpComponents from './blaze-plugins/blaze-plugin-ecommerce-kp-components/src';

export default function buildConfig(config) {
  // To set custom plugins into the project.
  config.plugins.set(examplePlugin, {});
  config.plugins.set(authWsbFe, {});
  config.plugins.set(ecommerceComponents, {});
  config.plugins.set(ecommerceKpComponents, {});

  // eslint-disable-next-line no-param-reassign
  config.apollo = {
    ...config.apollo,
    disableUpload: true
  };
  return config;
}
