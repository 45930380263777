import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { RouteContextProvider } from './RouteContext';

const RouteContextWrapper = ({ children }) => {
  const [contextState, setContextState] = useState({ previousUrl: '' });
  const router = useRouter();

  useEffect(
    () => {
      const handleRouteChange = () => setContextState({ previousUrl: router.asPath });
      router.events.on('routeChangeStart', handleRouteChange);

      return () => {
        router.events.off('routeChangeStart', handleRouteChange);
      };
    },
    [router.asPath, router.events]
  );

  return <RouteContextProvider value={{ ...contextState }}>{children}</RouteContextProvider>;
};

export default RouteContextWrapper;
