/* eslint-disable react/no-danger */
import Head from 'next/head';

const IndexPage = () => {
  if (process.browser) return null;

  const blazePreconnectOrigin = process.env.BLAZE_GRAPHQL_URI.slice(0, -8);

  return (
    <Head>
      <link rel="preconnect" href="//www.google.com" />
      <link rel="preconnect" href="https://cdn.koganpage.com" />
      <link rel="preconnect" href="https://safeframe.googlesyndication.com" />
      <link rel="preconnect" href={blazePreconnectOrigin} />
      <link
        rel="preload"
        href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
        as="style"
      />
      <link
        rel="preload"
        href="https://fonts.googleapis.com/icon?family=Material+Icons&amp;display=swap"
        as="style"
      />
      <script
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        defer
        type="text/javascript"
      />

      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GTM_ID}');`
        }}
      />
    </Head>
  );
};

export default IndexPage;
