const BREAKPOINTS_DEVICES = {
  MOBILE: 320,
  MOBILEBIG: 480,
  PHABLET: 660,
  TABLET: 768,
  DESKTOP: 960,
  WIDE: 1366
};

const FOOTER_DATA = {
  MENUS: [
    { href: '/contact-us', name: 'Contact' },
    { href: '/terms-conditions', name: 'Terms & Conditions' },
    { href: '/privacy', name: 'Privacy Policy' },
    { href: '/cookies', name: 'Cookie Policy' },
    { href: '/newsletter', name: 'Newsletter' }
  ],
  COPYRIGHT: '© 2021 KoganPage. All rights reserved.'
};

const HEADER_MENUS = [
  {
    name: 'News',
    href: '/news',
    items: []
  },
  {
    name: 'Products',
    href: '/products',
    items: [
      { itemKey: 1, href: '/products/audiobooks', name: 'Audiobooks' },
      { itemKey: 2, href: '/products/book-series', name: 'Book series' },
      { itemKey: 3, href: '/products/case-studies', name: 'Case studies' }
    ]
  },
  {
    name: 'Authors',
    href: '/authors',
    items: []
  },
  {
    name: 'Events',
    href: '/events',
    items: []
  },
  {
    name: 'About',
    href: '/about',
    items: [
      { itemKey: 1, href: '/about/accessibility', name: 'Accessibility' },
      { itemKey: 2, href: '/about/awards', name: 'Awards' }
    ]
  }
];

const SOCIAL_DATA = [
  {
    href: ' https://www.facebook.com/KoganPage',
    name: 'facebook',
    socialClass: 'facebook-f'
  },
  {
    href: 'https://twitter.com/Koganpage',
    name: 'twitter',
    socialClass: 'twitter'
  },
  {
    href: 'https://www.instagram.com/koganpage/',
    name: 'instagram',
    socialClass: 'instagram'
  }
];

const imageSizes = {
  standardWidths: {
    '320w': {
      r: {
        width: 320
      }
    },
    '480w': {
      r: {
        width: 480
      }
    },
    '660w': {
      r: {
        width: 660
      }
    },
    '768w': {
      r: {
        width: 768
      }
    },
    '960w': {
      r: {
        width: 960
      }
    },
    '1280w': {
      r: {
        width: 1280
      }
    }
  },
  squares: {
    '130w': {
      r: {
        width: '130',
        height: '130'
      }
    },
    '340w': {
      r: {
        width: '340',
        height: '340'
      }
    }
  },
  '3:2': {
    '320w': {
      r: {
        width: '320',
        height: '213'
      }
    },
    '480w': {
      r: {
        width: '480',
        height: '320'
      }
    },
    '660w': {
      r: {
        width: '660',
        height: '440'
      }
    },
    '768w': {
      r: {
        width: '768',
        height: '512'
      }
    },
    '1024w': {
      r: {
        width: '1024',
        height: '683'
      }
    },
    '1366w': {
      r: {
        width: '1366',
        height: '911'
      }
    }
  },
  '16:9': {
    '320w': {
      r: {
        width: 320,
        height: 180
      }
    },
    '480w': {
      r: {
        width: 480,
        height: 270
      }
    },
    '660w': {
      r: {
        width: 660,
        height: 371
      }
    },
    '768w': {
      r: {
        width: 768,
        height: 432
      }
    },
    '960w': {
      r: {
        width: 960,
        height: 540
      }
    },
    '1280w': {
      r: {
        width: 1280,
        height: 720
      }
    }
  }
};

const RESPONSIVE_IMAGE_CONFIG = process.env.DISABLE_RESPONSIVE_IMAGES
  ? null
  : {
      sizes: {
        socialMeta: {
          single: {
            r: {
              width: 1280
            }
          }
        },
        'card:landscape:grid--one:sidebar-card': {
          widths: {
            '320w': imageSizes['16:9']['320w'],
            '660w': imageSizes['16:9']['660w']
          },
          sizes: '(max-width: 960px) 40vw, 10vw'
        },
        // product image in RHS on article page
        'card:portrait:grid--one': {
          widths: imageSizes.standardWidths,
          sizes: '(min-width: 768px) 33vw, 100vw'
        },
        'card:portrait:grid--two': {
          // standard product card
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 768px) 50vw, (max-width: 1024px) 33vw, 16vw'
        },
        'card:portrait:grid--three': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 768px) 100vw, 33vw'
        },
        'card:portrait:grid--four': {
          widths: imageSizes['16:9'],
          sizes: '(max-width: 768px) 50vw, 25vw'
        },
        'card:landscape:grid--two': {
          widths: imageSizes['16:9'],
          sizes: '(max-width: 768px) 40vw, 10vw'
        },
        'card:landscape:grid--one': {
          widths: imageSizes['16:9'],
          sizes: '40vw'
        },
        // featured academics products on standard page
        'card:portrait:grid--four:card--featured-titles': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 768px) 50vw, (max-width: 1024px) 30vw, 20vw'
        },
        // 'Related articles/videos' on author page
        'card:portrait:grid--two:card-related-articles-videos': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 768px) 100vw, 33vw'
        },
        // 'Related content' on product page
        'card:portrait:grid--three:card-related-content': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 768px) 100vw, 33vw'
        },
        // 'Featured articles' on channel page
        'card:portrait:grid--three:card-featured-articles': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 768px) 100vw, 33vw'
        },
        // 'New books' on homepage, product listing, 'Related products' on product page
        'card:portrait:grid--six': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 768px) 50vw, (max-width: 1024px) 30vw, 20vw'
        },
        // product listing
        'card:portrait:grid--four:row--product-cards': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 768px) 50vw, (max-width: 1024px) 30vw, 20vw'
        },
        // author circle image on authors listing
        'card:portrait:grid--six:list-authors': {
          widths: imageSizes.squares,
          sizes: '30vw'
        },
        // author circle image on product page (About author)
        'card:landscape:grid--one:card-authors': {
          widths: imageSizes.squares,
          sizes: '10vw'
        },
        // author circle image on article page
        'card:landscape:grid--four:card-authors': {
          widths: imageSizes.squares,
          sizes: '10vw'
        },
        // related content on channel page
        'card:landscape:grid--one:card-featured-articles': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 768px) 10vw, 30vw'
        },
        // carousel with featured items on homepage
        'card:portrait:grid--one:card-featured-articles': {
          disabled: true
        },
        // featured titles on long form
        'card:landscape:grid--three:dark-background': {
          disabled: true
        },
        card: {
          widths: imageSizes['16:9'],
          sizes: '100vw'
        },
        globalLightbox: {
          widths: imageSizes.standardWidths,
          sizes: '100vw'
        },
        carousel: {
          widths: {
            // 525w is wrong but will trigger srcset correctly
            '525w': {
              r: {
                height: 350
              }
            }
          },
          sizes: '100vw'
        }
      }
    };

export { BREAKPOINTS_DEVICES, FOOTER_DATA, HEADER_MENUS, SOCIAL_DATA, RESPONSIVE_IMAGE_CONFIG };
