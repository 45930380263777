import { EcommerceContext, useEcommerceContext } from '@blaze-cms/ecommerce-context';

const SiteWrapper = ({ children }) => {
  const { currency } = useEcommerceContext(EcommerceContext);

  const currencyClass = currency ? `site-container--${currency}` : '';
  const className = `site-container ${currencyClass}`;
  return <div className={className}>{children}</div>;
};

export default SiteWrapper;
