const SELECTED_MODIFIER = '--selected';
const PUBLISHED_PREFIX = 'published_';
const QUANTITY_STRING = 'Quantity';
const CONTEXT_KEYS = {
  currencyKey: 'currency',
  variantKey: 'variant',
  cartIdKey: 'cartId',
  cartDataKey: 'cartData'
};

const LOCAL_CURRENCY_KEY = 'blaze_cart_currency_code';

const PRIVACY_POLICY_TEXT = 'For information on how we use your data read our';

// MOCKS for testing variantes, delete before merging
const VARIANTS_MOCK = [
  {
    __typename: 'EcommerceBaseVariantFormattedDefinition',
    variantId: 'printed',
    attributes: {
      nb_pages: '280',
      width: '235',
      height: '155',
      isbn: '9781398605374'
    },
    price: 29.99
  },
  {
    __typename: 'EcommerceBaseVariantFormattedDefinition',
    variantId: 'hardback',
    attributes: {
      nb_pages: '280',
      width: '240',
      height: '163',
      isbn: '9781398605398'
    },
    price: 95
  },
  {
    __typename: 'EcommerceBaseVariantFormattedDefinition',
    variantId: 'ebook',
    attributes: {
      nb_pages: '280',
      width: '',
      height: '',
      isbn: '9781398605381'
    },
    price: 29.99
  },
  {
    __typename: 'EcommerceBaseVariantFormattedDefinition',
    variantId: 'bundle',
    attributes: {
      nb_pages: '280',
      width: '235',
      height: '155',
      isbn: '9781398605404'
    },
    price: 29.99
  }
];

const COMPONENT_PREFIX = 'kp_';

export {
  SELECTED_MODIFIER,
  CONTEXT_KEYS,
  QUANTITY_STRING,
  VARIANTS_MOCK,
  LOCAL_CURRENCY_KEY,
  PUBLISHED_PREFIX,
  COMPONENT_PREFIX,
  PRIVACY_POLICY_TEXT
};
