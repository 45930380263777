import dynamic from 'next/dynamic';
import { PLUGIN_NAME } from './constants';
import { COMPONENT_PREFIX } from './Components/constants';

export default async function load(app) {
  app.events.once('load:custom:page-builder:component', addComponent => {
    addComponent({
      key: `${COMPONENT_PREFIX}productdetails`,
      component: dynamic(() =>
        import(/* webpackChunkName: "blazePbKpProductDetails" */ './Components/ProductDetails')
      )
    });
    addComponent({
      key: `${COMPONENT_PREFIX}newsletter`,
      component: dynamic(() =>
        import(/* webpackChunkName: "blazePbKpNewsletter" */ './Components/Newsletter')
      )
    });
    addComponent({
      key: `${COMPONENT_PREFIX}currencyselectmodal`,
      component: dynamic(() =>
        import(/* webpackChunkName: "blazePbKpCurrencySelectModal" */ './Components/CurrencySelectModal')
      )
    });
    addComponent({
      key: `${COMPONENT_PREFIX}icrequestbutton`,
      component: dynamic(() =>
        import(/* webpackChunkName: "blazePbKpInspectionCopyRequestButton" */ './Components/InspectionCopyRequest')
      )
    });
    addComponent({
      key: `${COMPONENT_PREFIX}file_link_wrapper`,
      component: dynamic(() =>
        import(/* webpackChunkName: "blazePbKPFileLinkWrapper" */ './Components/FileLinkWrapper')
      )
    });
  });

  return {
    name: PLUGIN_NAME
  };
}

load.pluginName = PLUGIN_NAME;
